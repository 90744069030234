import React from "react";
import Link from "next/link";
import Image from "next/image";
import dynamic from "next/dynamic";
import Skeleton from "react-loading-skeleton";
import theme from "../../themes/themes";
import themes from "../../themes/themes";
import { useTranslation } from "../../context/TranslationContext";
const MobileTopBar = dynamic(
  () => import("../../components/Header/MobileTopBar"),
  {
    loading: () => (
      <Skeleton
        count={3}
        width={40}
        height={40}
        circle={true}
        containerClassName="flex gap-x-0.5 ml-auto"
      />
    ),
  }
);
const Menu = dynamic(() => import("../../components/Header/Menu"));

const HeaderLeft = ({ headerContent }) => {
  const { headerLeft } = useTranslation();
  return (
    <div
      className={`flex items-center w-full lg:w-7/12 2xl:w-3/5 lg:bg-header-bg-white ${themes.header.borderClass}`}
    >
      <Link href="/">
        <a title={headerContent?.static?.Home.title} className="mr-1 2xl:mr6">
          <div className={`${themes.headerLogoClass} relative`}>
            <Image
              src={theme.logo}
              alt={process.env.NEXT_PUBLIC_SITENAME + " Logo"}
              layout="fill"
              objectFit="contain"
              priority={1}
            />
          </div>
          {/* <div className="w-[184px] h-[36px] lg:w-[232px] lg:h-[45px] relative">
            <Image
              src={theme.logo}
              alt="VillaVillam Logo"
              layout="fill"
              objectFit="contain"
              priority={1}
            />
          </div> */}
          {/* 
            <img
              src="/icons/logo.svg"
              alt="Logo"
              width="176"
              height="45"
              className="w-44 lg:w-40 2xl:w-auto"
            />
            */}
        </a>
      </Link>
      <nav className="hidden ml-5 xl:flex gap-x-1">
        {headerLeft.map((e, index) => (
          <Link href={e.url} key={index}>
            <a title={e.title}>{e.text}</a>
          </Link>
        ))}
      </nav>
      <MobileTopBar />
      <Menu headerContent={headerContent} />
    </div>
  );
};

export default HeaderLeft;
